@tailwind base;
@tailwind components;
@tailwind utilities;

.fullscreen {
  width: 100%;
  height: 100%;
}

@media print {
  .fullscreen {
    height: auto;
  }
}

@layer components {
  .dialog {
    @apply w-full h-full sm:w-2/3 sm:h-2/3;
  }

  @media print {
    .dialog {
      @apply w-full;
    }
  }

  .tippy-box[data-theme~='menu'] {
    @apply bg-white text-black border border-gray-300 rounded-t-none border-t-0;
  }
  .tippy-box[data-theme~='menu'] .tippy-content {
    @apply p-0 max-h-60 overflow-y-auto;
  }

  .tippy-box[data-theme~='auto-complete'] {
    @apply bg-white text-black border border-sky-600;
  }
  .tippy-box[data-theme~='auto-complete'] .tippy-content {
    @apply p-0 max-h-60 overflow-y-auto;
  }

  .tippy-box[data-theme~='white'] {
    @apply bg-white text-black border border-gray-300 shadow-md;
  }

  .tippy-box[data-theme~='white'] .tippy-content {
    @apply p-0;
  }

  .tippy-box[data-theme~='white'] > .tippy-arrow:after {
    content: "";
    position: absolute;
    z-index: -1
  }

  .tippy-box[data-theme~='white'] > .tippy-arrow:after {
    border-color: transparent;
    border-style: solid
  }

  .tippy-box[data-theme~='white'][data-placement^=bottom] > .tippy-arrow:before {
    @apply border-b-white;
    bottom: 16px
  }

  .tippy-box[data-theme~='white'][data-placement^=bottom] > .tippy-arrow:after {
    @apply border-b-gray-300;
    border-width: 0 7px 7px;
    bottom: 17px;
    left: 1px
  }
}

